
function About() {
    return (
        <div className="bg-gray-800 text-white" id="about">
            <div className="flex flex-col p-6 ">
                <div className="flex flex-col items-center justify-center">
                    <i class='bx bx-info-circle bx-lg'></i>
                    <h1 className="text-3xl underline mb-4">About Me</h1>
                </div>
                <p className="text-center">
                    Hi there! I am Kim! I've been coding for over 1.5 years now. As a Full Stack developer I've worked on small personal projects and freelance jobs. Along the journey I have realised my passion to solve problems in the society through technology. I deliver real value by developing efficient and reliable web apps.
                </p>
                <h1 className="mt-8 mx-auto text-2xl underline">Personal Details</h1>
                <div>
                    <span>Age</span>
                    <span>21</span>
                </div>
                <div>
                    <span>Phone</span>
                    <span>+254 758202697</span>
                </div>
                <div>
                    <span>Email</span>
                    <span>kimanikevin254@gmail.com</span>
                </div>
                <div>
                    <span>Address</span>
                    <span>Juja, Kenya</span>
                </div>
                <div>
                    <span>Job Status</span>
                    <span>Freelance</span>
                </div>
            </div>
        </div>
    )
}

export default About;
