import React from 'react'

function Dropdown( {isOpen} ) {
    return (
        <div>
            <ul className={ isOpen ? 'text-white bg-gray-900 fixed right-0 mt-12' : 'hidden' }>
                        <li className="px-16 py-2 hover:bg-white hover:text-gray-900">
                            <a href="#home">
                                Home
                            </a>
                        </li>
                        <li className="px-16 py-2 hover:bg-white hover:text-gray-900">
                            <a href="#about">
                                About
                            </a>
                        </li>
                        <li className="px-16 py-2 hover:bg-white hover:text-gray-900">
                            <a href="#home">
                                Works
                            </a>
                        </li>
                        <li className="px-16 py-2 hover:bg-white hover:text-gray-900">
                            <a href="#home">
                                Portfolio
                            </a>
                        </li>
                    </ul>
        </div>
    )
}

export default Dropdown
