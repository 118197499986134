import React, { useState, useEffect } from 'react'
import Typewriter from 'typewriter-effect';
import profilePic from '../Images/kim3.png';
import background1 from '../Images/background1.png';
import { MenuIcon } from '@heroicons/react/solid'
import Dropdown from './Dropdown'

function Header() {
    const kim = "</KIM>"

    const [isOpen, setIsOpen] = useState(false);

    const showDropdown = () => {
        setIsOpen(!isOpen);
    }
    
    useEffect(() => {
        const hideMenu = () => {
          if (window.innerWidth > 640 && isOpen) {
            setIsOpen(false);
          }
        };
    
        window.addEventListener('resize', hideMenu);
    
        return () => {
          window.removeEventListener('resize', hideMenu);
        };
      });

    return (
        <div className="h-screen overflow-hidden" style={{background: `url(${background1})`}}>
           <div className="w-screen flex justify-center items-center">
           <div className="fixed top-0 w-full md:w-4/5 flex items-center justify-between bg-gray-900 text-white p-3 text-xl">
                <h1 className="cursor-pointer">{kim}</h1>
                <div>
                    <ul className="hidden sm:flex">
                        <li className="py-2 px-6 hover:bg-white hover:text-gray-900">
                            <a href="#home">
                                Home
                            </a>
                        </li>
                        <li className="py-2 px-6 hover:bg-white hover:text-gray-900">
                            <a href="#about">
                                About
                            </a>
                        </li>
                        <li className="py-2 px-6 hover:bg-white hover:text-gray-900">
                            <a href="#home">
                                Works
                            </a>
                        </li>
                        <li className="py-2 px-6 hover:bg-white hover:text-gray-900">
                            <a href="#home">
                                Portfolio
                            </a>
                        </li>
                    </ul>
                </div>
                <MenuIcon className="h-5 w-5 cursor-pointer sm:hidden" onClick={showDropdown}/>
            </div>
           </div>
            <Dropdown isOpen={isOpen} />  
            <div className="bg-gray-800 flex flex-col items-center w-4/5 mx-auto mt-20 py-3 rounded-3xl md:flex md:flex-row-reverse md:justify-between">
                <div>
                    <img src={profilePic} className="rounded-full max-w-4/12 h-auto mt-3 md:mr-8 lg:max-w-sm p-2"></img>
                </div>

                <div className="md:w-1/2 md:flex md:flex-col md:items-start md:ml-8">
                    <h1 className="hidden text-lg text-white md:block ">Hello,</h1>
                    <h1 className="text-white text-center text-3xl my-2 ">KEVIN KIMANI</h1>
                    <div className="text-white text-2xl my-2 flex">
                        <span>
                        <Typewriter
                            options={{
                                strings: ['Front End', 'Back End', 'Full Stack'],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                        </span>
                        <span className="ml-1">
                            Web Developer
                        </span>
                    </div>
                    <div className="my-2 flex justify-center text-white text-xl">
                        <div className="flex items-center justify-center mr-2 h-8 w-8 border-2 rounded-full"> 
                        <i class='bx bxl-twitter'></i>
                        </div>
                        <div className="flex items-center justify-center mr-2 h-8 w-8 border-2 rounded-full"> 
                        <i class='bx bx-envelope'></i>
                        </div>
                        <div className="flex items-center justify-center mr-2 h-8 w-8 border-2 rounded-full"> 
                        <i class='bx bxl-linkedin'></i>
                        </div>
                    </div>
                    <a href="" className="hidden md:block py-1 px-2 text-white mt-2 border-2 rounded-lg">Hire Me</a>
                </div>
            </div>
            <div className="text-white mt-8 hidden lg:flex lg:justify-center">
                <a href="#about">
                    <i class='bx bx-chevron-down-circle bx-md'></i>
                </a>
            </div>
        </div>
    )
}

export default Header;
