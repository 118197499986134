import Header from "./Components/Header";
import About from "./Components/About";

import './index.css'; 
function App() {
  return (
     <div>
       <Header />
       <About /> 
       
     </div>
  );
}

export default App;
